<template>
  <v-sheet class="customer" id="customer">
    <v-row>
      <v-col md="12">
        <v-row>
          <v-col md="6" class="my-auto py-0">
            <h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
          </v-col>
          <v-col md="6" class="text-right py-0">
            <v-btn
              :disabled="pageLoading || !formValid"
              :loading="pageLoading"
              class="white--text mr-2"
              depressed
              color="blue darken-4"
              tile
              v-on:click="updateOrCreate"
            >
              Save
            </v-btn>
            <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col md="12" class="mt-0 pt-0">
        <v-form
          ref="productAddForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate"
        >
          <v-container fluid class="pt-0">
            <v-row>
              <!-- with no more values -->
              <v-col md="9" class="pt-0">
                <v-row class="pt-0 p-4 pl-1">
                  <v-col md="12" class="">
                    <v-row>
                      <v-col md="2" class="my-auto py-0">
                        <label for="bills-number" class="field-label required">Bill# </label>
                      </v-col>
                      <v-col md="4" class="my-1 py-0">
                        <TextInput
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="bills-number"
                          placeholder=""
                          v-model="billsData.bills_number"
                        >
                        </TextInput>
                      </v-col>

                      <v-col md="2" class="my-auto py-0 text-end">
                        <label for="payment-terms" class="field-label">Payment Terms </label>
                      </v-col>
                      <v-col md="4" class="my-1 py-0">
                        <v-select
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          :items.sync="paymentTermsList"
                          id="payment-terms"
                          outlined
                          v-model="billsData.paymentTemns"
                          placeholder="Payment Terms"
                        >
                        </v-select>
                      </v-col>

                      <!-- <v-col md="2" class="my-auto py-0">
                        <label for="order-number" class="field-label">Order Number </label>
                      </v-col>
                      <v-col md="4" class="my-2 py-0">
                        <TextInput
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="order-number"
                          placeholder=""
                          v-model="billsData.order_number"
                        >
                        </TextInput>
                      </v-col> -->

                      <v-col md="2" class="my-auto py-0">
                        <label for="due-date" class="field-label required">Bill Date </label>
                      </v-col>
                      <v-col md="4" class="my-1 py-0">
                        <DatePicker
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="due-date"
                          v-model="billsData.due_date"
                          placeholder="Due Date"
                        ></DatePicker>
                      </v-col>

                      <v-col md="2" class="my-auto py-0 text-end">
                        <label for="due-date" class="field-label">Due Date </label>
                      </v-col>
                      <v-col md="4" class="my-1 py-0">
                        <DatePicker
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="due-date"
                          v-model="billsData.due_date"
                          placeholder="Due Date"
                        ></DatePicker>
                      </v-col>
                      <v-col md="2" class="my-auto py-0 pr-0">
                        <label for="ref-number" class="field-label"
                          >Ref Number
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" color="disabled" size="18"
                                >mdi-help-circle-outline</v-icon
                              >
                            </template>
                            <span>Reference Number Printed on pdf</span>
                          </v-tooltip>
                        </label>
                      </v-col>
                      <v-col md="4" class="my-2 py-0">
                        <TextInput
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="ref-number"
                          placeholder="Ref Number"
                          v-model="billsData.refNumber"
                        >
                        </TextInput>
                      </v-col>

                      <v-col md="2" class="my-auto py-0 text-end">
                        <label for="po-number" class="field-label">Purchase Order# </label>
                      </v-col>
                      <v-col md="4" class="my-2 py-0">
                        <TextInput
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          id="po-number"
                          v-model="billsData.poNumber"
                        >
                        </TextInput>
                      </v-col>

                      <v-col md="2" class="my-auto py-0">
                        <label for="due-date" class="field-label required">Supplier </label>
                      </v-col>
                      <v-col md="10" class="my-2 py-0">
                        <label for="project" class="field-label required"
                          >Note: Changing supplier will reset all line items.</label
                        >
                        <v-select
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          :items.sync="supplierList"
                          id="supplier"
                          outlined
                          v-model="billsData.supplier"
                          placeholder="Select Supplier"
                          :rules="[vrules.required(billsData.supplier, 'Supplier')]"
                          :class="{ required: !billsData.supplier }"
                        >
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content v-text="data.item"></v-list-item-content>
                            </template>
                            <template v-else>
                              <v-list-item-avatar>
                                <img :src="data.item.avatar" />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title v-html="data.item.text"></v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-select>
                      </v-col>
                      <v-col md="2" class="py-0"></v-col>
                      <v-col md="10" class="py-0">
                        <v-row class="" v-if="billsData.supplier">
                          <v-col md="4" class="my-0 py-0">
                            <h6 class="blue--text text--darken-4 text-h6">SUPPLIER</h6>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                              <span>Maxwell</span>
                            </div>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                              <span>Maxwell@gmial.com</span>
                            </div>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                              <span>+65 1234 5678</span>
                            </div>
                          </v-col>
                          <v-col md="4" class="my-0 py-0">
                            <h6 class="blue--text text--darken-4 text-h6">PERSON INCHARGE</h6>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                              <span>Maxwell</span>
                            </div>
                            <div>
                              <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                              <span>Maxwell@gmial.com</span>
                            </div>
                          </v-col>
                          <v-col md="4" class="my-0 py-0">
                            <h6 class="blue--text text--darken-4 text-h6">ADDRESS</h6>
                            <div>
                              <div class="d-flex">
                                <div>
                                  <v-icon color="disabled" size="18" class="me-1">mdi-map</v-icon>
                                </div>
                                <span
                                  >singapore<br />
                                  456 MacPherson Road<br />
                                  Geylang<br />
                                  Singapore<br />
                                  368174</span
                                >
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col md="2" class="pb-0 my-auto">
                        <label for="dept-category" class="field-label mt-0 mb-1"></label>
                      </v-col>
                      <v-col md="10" class="my-0 py-0">
                        <v-checkbox
                          v-model="billsData.supplier_product"
                          label="Show Only this supplier product"
                          value="Show Only this supplier product"
                        ></v-checkbox>
                      </v-col>
                      <v-col md="2" class="py-0 my-auto">
                        <label for="supplier" class="field-label my-0">Received Status</label>
                      </v-col>
                      <v-col md="4" class="my-0 py-0 custom_toggle">
                        <v-btn-toggle
                          v-model="received_status"
                          tile
                          group
                          divided
                          color="blue"
                          variant="plain"
                          mandatory
                        >
                          <v-btn
                            color="green"
                            height="34"
                            variant="flat"
                            style="border: 2px solid rgb(34, 167, 93)"
                          >
                            Received
                          </v-btn>

                          <v-btn
                            color="red"
                            variant="flat"
                            style="border: 2px solid #c42f22"
                            height="34"
                          >
                            Pending
                          </v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col md="2" class="my-auto py-0 text-end">
                        <label for="delivery-date" class="field-label">Receiving Warehouse </label>
                      </v-col>
                      <v-col md="4" class="my-0 py-0">
                        <v-select
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          :items.sync="receivingList"
                          id="receiving"
                          outlined
                          v-model="billsData.receivingAdd"
                          placeholder="Receining Warehouse"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="3" class="pt-0">
                <div class="py-0 text-right col-md-12 col">
                  <span class="font-weight-bold" style="font-size: 24px">Amount</span><br />
                  <div class="blue--text text--darken-4" style="font-size: 40px; font-weight: 700">
                    $0.00
                  </div>
                </div>
              </v-col>
              <!-- Line items -->
              <v-col md="12" class="blue lighten-5">
                <v-row>
                  <v-col md="6" class="my-auto py-0">
                    <p class="my-0 font-weight-bold">Line items</p>
                    <span
                      ><v-icon color="disabled" size="18">mdi-help-circle-outline</v-icon>Specify
                      the Line Items for your Purchase order</span
                    >
                  </v-col>
                  <v-col md="6" class="text-right py-0">
                    <v-btn
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      class="white--text mr-2"
                      depressed
                      color="blue darken-4"
                      tile
                      v-on:click="importDialog = true"
                    >
                      <v-icon color="disabled" size="15">mdi-file-excel</v-icon>
                      UPload Excel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="3" class="py-2">
                <div class="d-flex align-center">
                  <TextInput
                    hide-details
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    id="search-line-item"
                    class="mt-0"
                    placeholder="Search Line Item.."
                    v-model="search_line_item"
                  >
                  </TextInput>
                  <v-btn
                    style="height: 35px"
                    class="white--text"
                    depressed
                    color="blue darken-4"
                    tile
                  >
                    <v-icon left>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col md="9" class="py-2 d-flex justify-content-end">
                <label for="supplier" class="field-level mt-2 me-2 font-weight-bold"
                  >Discount Level:</label
                >
                <v-radio-group v-model="discount_level" row>
                  <v-radio label="Line item" value="lineItems"></v-radio>
                  <v-radio label="Translation" value="translation"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col md="12" class="pt-0">
                <div
                  class="mb-5 position-relative"
                  style="max-height: calc(100vh - 300px); overflow-y: auto"
                >
                  <!-- 👉 Item Header -->
                  <div class="w-100 po-line-items">
                    <table width="100%">
                      <thead>
                        <tr>
                          <th class="pa-2" width="40"></th>
                          <th class="pa-2 text-center" width="40">#</th>
                          <th class="pa-2" width="300">Product</th>
                          <th class="pa-2" width="300">Description</th>
                          <th class="pa-2" width="150">UOM</th>
                          <th class="pa-2" width="120">Qty</th>
                          <th class="pa-2 text-right" width="120">Rate</th>
                          <th class="pa-2 text-right" width="120">Amount</th>
                          <th
                            class="pa-2 text-right"
                            width="120"
                            v-if="discount_level == 'lineItems'"
                          >
                            Discount
                          </th>
                          <th class="pa-2 text-right" width="40"></th>
                        </tr>
                      </thead>
                      <!-- <tbody > -->
                      <Draggable
                        tag="tbody"
                        v-model="line_items"
                        class="draggable-group"
                        handle=".draggable-drag-icon"
                        v-clickoutside="outsideClicked"
                        v-on:change="updateItemOrder($event)"
                      >
                        <tr v-for="(row, index) in line_items" :key="index">
                          <td class="py-1 px-2">
                            <v-icon class="draggable-drag-icon cursor-move" color="blue darken-4"
                              >mdi-drag</v-icon
                            >
                          </td>
                          <td class="py-1 px-2 text-center">{{ index + 1 }}</td>
                          <td
                            class="py-1 px-2"
                            @click="editRow($event, index)"
                            :class="row.isEditable ? 'px-2' : 'px-3'"
                          >
                            <template v-if="row.isEditable">
                              <div class="d-flex align-center">
                                <div>
                                  <v-avatar size="40px">
                                    <img
                                      max-height="40"
                                      max-width="40"
                                      src="https://static.fnac-static.com/multimedia/Images/FD/Comete/80444/CCP_IMG_ORIGINAL/1008963.jpg"
                                      alt="John"
                                    />
                                  </v-avatar>
                                </div>

                                <v-select
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  :items.sync="productList"
                                  id="supplier"
                                  outlined
                                  v-model="row.product"
                                >
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-avatar>
                                        <img :src="data.item.avatar" />
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          v-html="data.item.text"
                                        ></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-select>
                              </div>
                            </template>
                            <template v-else class="px-1">{{ row.product }}</template>
                          </td>
                          <td
                            style="max-width: 300px"
                            @click="editRow($event, index)"
                            class="py-1 px-2 text-truncate"
                            :class="row.isEditable ? 'px-2' : 'px-3'"
                          >
                            <TextInput
                              v-if="row.isEditable"
                              hide-details
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              id="description"
                              placeholder=""
                              v-model="row.description"
                            >
                            </TextInput>
                            <template v-else class="px-1">{{ row.description }}</template>
                          </td>
                          <td
                            class="py-1 px-2"
                            @click="editRow($event, index)"
                            :class="row.isEditable ? 'px-2' : 'px-3'"
                          >
                            <v-select
                              v-if="row.isEditable"
                              hide-details
                              outlined
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              :items.sync="uomList"
                              id="uom"
                              v-model="row.uom"
                              placeholder="UOM"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-badge
                                    class="mt-4 me-5 mb-2"
                                    :color="data.item.color"
                                  ></v-badge>
                                  <v-list-item-content class="py-2">
                                    <v-list-item-title v-html="data.item.text"></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-select>
                            <template v-else class="px-1">{{ row.uom }}</template>
                          </td>
                          <td
                            class="py-1 px-2"
                            @click="editRow($event, index)"
                            :class="row.isEditable ? 'px-2' : 'px-3'"
                          >
                            <TextInput
                              v-if="row.isEditable"
                              hide-details
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              id="quantity"
                              type="number"
                              placeholder=""
                              v-model="row.quantity"
                            >
                            </TextInput>
                            <template v-else class="px-1">{{ row.quantity }}</template>
                          </td>
                          <td
                            class="py-1 px-2 text-right"
                            @click="editRow($event, index)"
                            :class="row.isEditable ? 'px-2' : 'px-3'"
                          >
                            <TextInput
                              v-if="row.isEditable"
                              hide-details
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              id="rate"
                              placeholder=""
                              v-model="row.rate"
                            >
                            </TextInput>
                            <template v-else class="px-1">{{ row.rate }}</template>
                          </td>
                          <td
                            @click="editRow($event, index)"
                            class="py-1 px-2 text-right"
                            :class="row.isEditable ? 'px-2' : 'px-3'"
                          >
                            <template class="px-1">{{ row.total }}</template>
                          </td>
                          <td
                            v-if="discount_level == 'lineItems'"
                            @click="editRow($event, index)"
                            class="py-1 px-2 text-right"
                            :class="row.isEditable ? 'px-2' : 'px-3'"
                          >
                            <div class="d-flex align-items-center justify-content-end">
                              <div style="max-width: 60px" class="">
                                <TextInput
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id="discount-value"
                                  type="number"
                                  class="mt-0"
                                  v-model="discount_value"
                                >
                                </TextInput>
                              </div>
                              <div style="max-width: 80px" class="">
                                <SelectInput
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  :items.sync="discountTypeList"
                                  id="discount-type-list"
                                  class="mt-0 text-center"
                                  v-model="discount_value_type"
                                >
                                </SelectInput>
                              </div>
                            </div>
                          </td>
                          <td class="py-1 px-2 text-right">
                            <v-icon
                              color="red"
                              @click="deleteLineItems(index)"
                              :disabled="line_items.length == 1 ? true : false"
                              >mdi-delete-outline</v-icon
                            >
                          </td>
                        </tr>
                      </Draggable>
                      <!-- </tbody> -->
                      <tfoot>
                        <tr>
                          <td class="pa-2 borer-none" colspan="4">
                            <v-btn
                              @click="addLineItems()"
                              class="white--text"
                              depressed
                              color="blue darken-4"
                              tile
                            >
                              <v-icon left>mdi-plus</v-icon>
                              Add Line item
                            </v-btn>
                          </td>
                          <td class="pa-2 borer-none text-right" colspan="3"><b>Sub Total</b></td>
                          <td class="pa-2 borer-none text-right" width="180">$0.00</td>
                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                        <tr v-if="discount_level !== 'lineItems'">
                          <td class="pa-2 borer-none text-right" colspan="7">
                            <div class="d-flex align-items-center justify-content-end">
                              <b>Discount</b>
                              <div style="max-width: 80px" class="ml-2">
                                <TextInput
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id="discount-value"
                                  type="number"
                                  class="mt-0"
                                  v-model="discount_value"
                                >
                                </TextInput>
                              </div>
                              <div style="max-width: 80px" class="">
                                <SelectInput
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  :items.sync="discountTypeList"
                                  id="discount-type-list"
                                  class="mt-0 text-center"
                                  v-model="discount_value_type"
                                >
                                </SelectInput>
                              </div>
                            </div>
                          </td>
                          <td class="pa-2 borer-none text-right" width="180">$0.00</td>
                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                        <tr>
                          <td class="pa-2 borer-none text-right" colspan="7">
                            <div class="d-flex align-items-center justify-content-end">
                              <v-checkbox
                                hdie-details
                                v-model="tax_applied"
                                class="ma-0 pt-0"
                                hide-details
                                small
                                id="is-tax"
                                v-on:change="getTax()"
                                dense
                                color="blue darken-4"
                              ></v-checkbox>
                              <b>Tax</b>
                              <div style="max-width: 60px" class="ml-2">
                                <span class="font-weight-bold" style="font-size: 16px">
                                  {{ tax_value }} %
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="pa-2 borer-none text-right" width="180">$0.00</td>
                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                        <tr>
                          <td class="pa-2 borer-none text-right" colspan="7">
                            <div class="d-flex align-items-center justify-content-end">
                              <b>Adjustment</b>
                              <div style="max-width: 60px" class="ml-2">
                                <TextInput
                                  hide-details
                                  :disabled="pageLoading"
                                  :loading="pageLoading"
                                  id="discount-value"
                                  type="number"
                                  class="mt-0"
                                  v-model="adjustment"
                                >
                                </TextInput>
                              </div>
                            </div>
                          </td>
                          <td class="pa-2 borer-none text-right" width="180">$0.00</td>
                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                        <tr>
                          <td class="pa-2 borer-none text-right" colspan="7">
                            <b style="font-size: 16px">Grand Total</b>
                          </td>
                          <td class="pa-2 borer-none text-right" width="180">
                            <b style="font-size: 16px"> $0.00</b>
                          </td>
                          <td class="pa-2 borer-none text-right" width="40"></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </v-col>
              <!-- Attachments & Other details -->
              <v-col md="12" class="blue lighten-5">
                <v-row>
                  <v-col md="12" class="my-auto py-0">
                    <p class="my-0 font-weight-bold">Attachments & Other details</p>
                    <span
                      ><v-icon color="disabled" size="18">mdi-help-circle-outline</v-icon> Specify
                      the Attachments & Other details for your Purchase order</span
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="9" class="pt-0">
                <v-row class="py-3">
                  <v-col md="2" class="my-4">
                    <label for="attachments" class="field-label">Attachments</label>
                  </v-col>
                  <v-col md="10">
                    <FileUpload
                      id="file"
                      :directionColumn="false"
                      accept="image"
                      allowAddMore
                      v-model="billsData.file"
                    ></FileUpload>
                  </v-col>
                  <v-col md="2" class="">
                    <label for="remark" class="field-label">
                      Remark
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="disabled" size="18"
                            >mdi-help-circle-outline</v-icon
                          >
                        </template>
                        <span>Remark Printed on pdf</span>
                      </v-tooltip>
                    </label>
                  </v-col>
                  <v-col md="10" class="">
                    <TextAreaInput
                      v-model="billsData.remark"
                      counter="1024"
                      auto-grow
                      @keypress="checkDoubleCurly($event, billsData.remark)"
                      :disabled="pageLoading"
                      :rules="[
                        vrules.required(billsData.remark, 'Remark'),
                        (v) =>
                          (v && !hideDetails ? v.length <= 1024 : true) ||
                          `Remark maximum 250 characters`,
                      ]"
                      :loading="pageLoading"
                      id="remark"
                      placeholder="Remark"
                    ></TextAreaInput>
                  </v-col>
                  <v-col md="2" class="my-auto">
                    <label for="terms-onditions" class="field-label"> Terms & Conditions </label>
                  </v-col>
                  <v-col md="10" class="py-0">
                    <SelectInput
                      hide-details
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      :items.sync="termsConditionList"
                      id="terms-onditions"
                      v-model="billsData.terms_conditions"
                      placeholder="Select Terms & Conditions"
                      append-outer-icon="mdi-cog"
                      v-on:click:append-outer="manageTermsDialog = true"
                    >
                    </SelectInput>
                  </v-col>
                  <v-col md="2" class="my-auto">
                    <label for="terms" class="field-label"> </label>
                  </v-col>
                  <v-col md="10" class="py-3">
                    <TextAreaInput
                      v-model="billsData.terms_conditions"
                      counter="1024"
                      auto-grow
                      @keypress="checkDoubleCurly($event, billsData.terms_conditions)"
                      :disabled="pageLoading"
                      :rules="[
                        vrules.required(billsData.terms_conditions, 'Terms & Conditions'),
                        (v) =>
                          (v && !hideDetails ? v.length <= 1024 : true) ||
                          `Terms & Conditions maximum 250 characters`,
                      ]"
                      :loading="pageLoading"
                      id="terms_conditions"
                      placeholder="Terms & Conditions"
                    ></TextAreaInput>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
    <Dialog :dialog="importDialog" card-text-class="px-4 py-0">
      <template v-slot:title> UPLOAD EXCEL FILE</template>
      <template v-slot:body>
        <v-container>
          <v-btn
            color="blue darken-4"
            depressed
            tile
            class="mr-4 white--text"
            v-on:click="downloadSample()"
            >Download Sample</v-btn
          >
          <h4 class="mt-4">Download Sample For Line Items</h4>
          <v-layout class="my-4">
            <v-flex md2>
              <label for="item-file-upload" class="btx-label mt-2 required">File</label>
            </v-flex>
            <v-flex md10>
              <v-file-input
                v-model="excelFile"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref="fileInput"
                hide-details
                id="item-file-upload"
                label="Excel File"
                outlined
                dense
              ></v-file-input>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="uploadExcel()"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="importDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <Dialog :dialog="manageTermsDialog" card-text-class="px-4 py-0">
      <template v-slot:title>
        <span>MANAGE TERMS & CONDITIONS</span> <v-spacer></v-spacer
        ><v-btn @click="addTerms()" color="blue darken-4" depressed tile class="mr-4 white--text"
          ><v-icon>mdi-plus</v-icon>Add</v-btn
        ></template
      >
      <template v-slot:body>
        <v-container>
          <template v-for="(item, index) in manageTermsList">
            <v-layout class="bg_grey pt-4 pb-2 px-3 my-2" :key="index.id">
              <v-flex md11>
                <TextInput
                  hide-details
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="terms-condition"
                  placeholder="Terms & Conditions"
                  v-model="item.terms_condition"
                  class="mb-2 required"
                >
                </TextInput>
                <TextAreaInput
                  v-model="item.description"
                  auto-grow
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  id="description"
                  placeholder="Description"
                ></TextAreaInput>
              </v-flex>
              <v-flex md1 class="my-auto">
                <label for="item-file-upload" class="btx-label">
                  <!-- <v-btn icon="mdi-delete" fab dark small tile class="me-4 white--red"></v-btn> -->
                  <v-btn class="ms-4" icon color="red" @click="removeTerms(index)">
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                </label>
              </v-flex>
            </v-layout>
          </template>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text mr-2"
          depressed
          color="blue darken-4"
          tile
          :disabled="excelLoading"
          :loading="excelLoading"
          v-on:click="uploadExcel()"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-2"
          :disabled="excelLoading"
          depressed
          tile
          v-on:click="manageTermsDialog = false"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </v-sheet>
</template>

<script>
import { toSafeInteger } from "lodash";
import Draggable from "vuedraggable";
import DatePicker from "@/view/components/DatePicker";
import TextInput from "@/view/components/TextInput";
import Dialog from "@/view/components/Dialog";
//import NumberInput from "@/view/components/NumberInput";
import SelectInput from "@/view/components/SelectInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import FileUpload from "@/view/components/FileUpload";
//import POLineItems from "@/view/components/POLineItems";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { CREATE_CUSTOMER, UPDATE_CUSTOMER, GET_CUSTOMER } from "@/core/lib/customer.lib";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";

export default {
  name: "Purchase-order-create",
  title: "Create Purchase Order",
  data() {
    return {
      discount_level: "translation",
      tax_applied: true,
      pageLoading: false,
      formValid: false,
      productId: null,
      search_line_item: null,
      line_items: [
        {
          isEditable: false,
          product: null,
          description: null,
          quantity: 1,
          order: 1,
          uom: null,
          rate: 0,
          total: 0,
        },
      ],
      taxList: [8],
      tax_value: 8,
      discount_value: 0,
      discount_value_type: 1,
      adjustment: 0,
      adjustment_amount: 0,
      discountTypeList: [
        { text: "$", value: 1 },
        { text: "%", value: 2 },
      ],
      // productList: [],
      // allProductList: [],

      supplierList: [
        {
          text: "S00001 - Genic Solution",
          value: "genic-solution",
          avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        },
        {
          text: "S00002 - Bt Solution",
          value: "bt-solution",
          avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        },
      ],
      receivingList: [
        {
          text: "8 Burn Road, #07-16 Trivex, Singapore 369977",
          value: "address-1",
        },
        {
          text: "Singapore 369977",
          value: "address-2",
        },
      ],
      paymentTermsList: [
        {
          text: "Net 30",
          value: "net-30",
        },
        {
          text: "Net 45",
          value: "net-45",
        },
      ],
      uomList: [
        {
          text: "Can",
          value: "can",
          color: "blue",
        },
        {
          text: "Ctn",
          value: "ctn",
          color: "green",
        },
      ],
      productList: [
        {
          text: "P010 - screw",
          value: "screw",
          avatar:
            "https://static.fnac-static.com/multimedia/Images/FD/Comete/80444/CCP_IMG_ORIGINAL/1008963.jpg",
        },
        {
          text: "P003 - iphone",
          value: "iphone",
          avatar: "https://th.bing.com/th/id/OIP.zK1Wp6Zd0K9M3cJIhKFkOQHaHa?pid=ImgDet&rs=1",
        },
      ],
      termsConditionList: [
        {
          text: "Purchasing order next",
          value: "purchasing order next",
        },
        {
          text: "Purchasing order terms",
          value: "purchasing order terms",
        },
      ],

      // supplier_form
      manageTermsList: [
        {
          terms_condition: "Purchasing order next",
          description:
            "deliverable or other product or result from Shervices that is referred to in a Purchase Order.",
        },
        {
          terms_condition: "",
          description: "",
        },
      ],
      received_status: [0],
      importDialog: false,
      manageTermsDialog: false,
      billsData: {
        refNumber: null,
        poNumber: null,
        due_date: null,
        bills_number: null,
        paymentTemns: "Due on Receipt",
        supplier: null,
        receivingAdd: null,
        supplier_product: false,
        termsConditionList: null,
        terms_conditions: null,
        remark: null,
        files: [],
      },
    };
  },
  components: {
    TextInput,
    SelectInput,
    TextAreaInput,
    Dialog,
    Draggable,
    //POLineItems,
    FileUpload,
    DatePicker,
  },
  methods: {
    outsideClicked() {
      if (!this.isOutside) {
        const _line_items = this.line_items.map((row) => {
          return { ...row, isEditable: false };
        });
        this.line_items = _line_items;
        this.isOutside = true;
      }
    },
    editRow(event, index) {
      this.isOutside = false;
      const element = event.target;
      //console.log(element.nodeName);
      const _line_items = this.line_items.map((row) => {
        return { ...row, isEditable: false };
      });
      this.line_items = _line_items;
      this.line_items[index].isEditable = true;
      this.$nextTick(() => {
        const inputarea = element.querySelector("input");
        if (inputarea) {
          inputarea.focus();
          inputarea.select();
        }
      });
      if (element.nodeName == "INPUT") {
        element.select();
      }
      if (this.line_items.length == index + 1) {
        this.addLineItems();
      }
    },
    deleteLineItems(index) {
      this.line_items.splice(index, 1);
    },
    addLineItems() {
      const _order = this.line_items.length;
      this.line_items.push({
        isEditable: false,
        id: null,
        uuid: null,
        product: null,
        description: null,
        uom: null,
        quantity: 1,
        rate: 0,
        total: 0,
        order: _order + 1,
      });
      this.$emit("input", this.line_items);
    },
    addTerms() {
      this.manageTermsList.push({
        terms_condition: null,
        description: null,
      });
    },
    removeTerms(index) {
      this.manageTermsList.splice(index, 1);
    },
    pageTitle() {
      if (this.productId) {
        return "Update Product";
      }
      return "New Bill";
    },
    async updateOrCreate() {
      const _this = this;

      if (!_this.$refs.productAddForm.validate()) {
        return false;
      }

      try {
        _this.pageLoading = true;
        if (_this.customerId) {
          console.log(this.product);
          const customer = await UPDATE_CUSTOMER(_this.customerId, _this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Updated Successfully." },
          ]);
        } else {
          const customer = await CREATE_CUSTOMER(_this.customer);
          _this.$router.replace({
            name: "customer-detail",
            params: { id: customer.id },
            query: { t: new Date().getTime() },
          });
          _this.$store.commit(SET_MESSAGE, [
            { model: true, message: "Customer Created Successfully." },
          ]);
        }
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getCustomer() {
      const customer = await GET_CUSTOMER(this.customerId);
      this.customer = {
        title: customer.title,
        first_name: customer.first_name,
        last_name: customer.last_name,
        email: customer.email,
        date_of_birth: customer.date_of_birth,
        gender: customer.gender,
        phone_number: customer.phone_number,
        address_line_1: customer.address_line_1,
        address_line_2: customer.address_line_2,
        unit_number: customer.unit_number,
        postal_code: customer.postal_code,
        country: customer.country,
      };
      this.$store.dispatch(SET_BREADCRUMB, [
        { text: "Customer", disabled: true },
        { text: "Update", disabled: true },
        { text: customer.barcode, disabled: true },
      ]);
    },
    addSupplier() {
      const supplier = { ...this.supplier, id: this.billsData.suppliers.length + 1 };
      this.billsData.suppliers.push(supplier);
    },

    removeSupplier(index) {
      this.billsData.suppliers.splice(index, 1);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Create", disabled: true },
    ]);

    const { name, params } = this.$route;
    if (name === "product-update") {
      const { id } = params;
      if (id) {
        this.productId = toSafeInteger(id);
        this.getCustomer();
      } else {
        this.goBack();
      }
    }
  },
};
</script>

<style scoped>
.v-input {
  margin-top: 0% !important;
}

.field-label {
  font-size: 1rem;
  font-weight: 500;
}

.left-idf-primary {
  border-left: 10px solid #5cb85c;
  border-top: 1px solid #5cb85c;
  border-bottom: 1px solid #5cb85c;
  border-right: 1px solid #5cb85c;
}

.idf_mark_primary {
  background-color: #5cb85c;
}

.idf_mark_secondary {
  background-color: #e64a4a;
}

.left-idf-secondary {
  border-left: 10px solid #e64a4a;
  border-top: 1px solid #e64a4a;
  border-bottom: 1px solid #e64a4a;
  border-right: 1px solid #e64a4a;
}
.bg_grey:hover {
  background-color: #f5f5f5 !important;
}
.po-line-items th {
  font-weight: 500;
}
.po-line-items th,
.po-line-items td:not(.borer-none) {
  border-bottom: 1px solid #c7c7c7;
  border-left: 1px solid #c7c7c7;
  height: 40px;
}
.po-line-items th:first-child,
.po-line-items td:first-child {
  border-left: none !important;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  /* opacity: 1; */
  /* background-color: #0abb87; */
}
.custom_toggle
  button.v-btn.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.green {
  background-color: rgb(34, 167, 93) !important;
  color: #fff;
}
.custom_toggle
  button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.red {
  background-color: rgb(196, 47, 34) !important;
  color: #fff;
}
</style>
